import React, { useState, useEffect } from "react"
import { graphql, navigate } from 'gatsby'
import Slider from "react-slick"
import Img from "gatsby-image/withIEPolyfill"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticoloBox from '../components/articolo-box'
import TextDecorator from '../components/text-decorator'


// const PrevArrow = ({ onClick, className }) => <button onClick={e => clickStop(e, onClick)} type="button" ></button>;
// const NextArrow = ({ onClick, className }) => <button onClick={e => clickStop(e, onClick)} type="button" ></button>;

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: true,
  // prevArrow: <PrevArrow />,
  // nextArrow: <NextArrow />,
  slidesToShow: 1,
  slidesToScroll: 1
};

const IndexPage = ({ data }) => {
  const { slider, abilitaHeroSafeArea, articoliBox } = data.home;

  const [stopClick, setStopClick] = useState(false);
  settings.onSwipe = () => setStopClick(true);
  useEffect(() => {
    if (stopClick) {
      const timeout = setTimeout(() => {
        setStopClick(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [stopClick]);

  const navigateSlide = (slide) => {
    if (slide.linkCta && !stopClick) {
      navigate(slide.linkCta.url);
    }
  }

  return (
    <Layout data={data}>
      <SEO page={data.home} data={data} />
      <div id="main" role="main">
        <Slider {...settings} className="slider-hero">
          {slider.map((slide, i) => (
            <div key={i} onClick={() => navigateSlide(slide)} className={`${slide.linkCta ? 'slider-hero__link' : ''}`}>
              <div className={`slider-hero__fig ${abilitaHeroSafeArea ? 'slider-hero__fig--safe-area' : 'slider-hero__fig--no-safe-area'}`} 
                style={{ backgroundImage: abilitaHeroSafeArea ? `url(${slide.immagine.fixedHeight.src})` : 'none' }}>
                {!abilitaHeroSafeArea && <Img fixed={slide.immagine.fixedWidth} objectFit="cover" objectPosition="center center" alt={slide.titolo} className="slider-hero__img" />}
              </div>
              <div className="slider-hero__wrapper-caption">
                <div className="slider-hero__caption d-flex flex-column flex-lg-row">
                  <div className="slider-hero__title">
                    <TextDecorator>{slide.titolo}</TextDecorator>
                  </div>
                  {slide.sottotitolo && <div className="slider-hero__subject"><TextDecorator>{slide.sottotitolo}</TextDecorator></div>}
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <div className="wrapper-box-home">
          <div className="container">
            <div className="row">
              {articoliBox.map((box, i) => (
                <div className="col-md-4" key={i}>
                  <ArticoloBox box={box} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage

export const query = graphql`
query IndexQuery{
  ...DefaultSeo
  ...HeaderFragment
  ...FooterRootFragment
  home: contentfulLayoutHomepage(contentful_id: {eq: "1ErWdh58NrPdhIBmr4RXI0"}) {
    seo {
      title
      friendlyUrl
      description
      robots
      ogtitle
      ogdescription
      ogimage{
        fixed(width: 1920, quality: 100){
          src
        }
      }
    }
    slider {
      immagine {
        fixedWidth: fixed(width:1920) {
          ...GatsbyContentfulFixed_withWebp
        }
        fixedHeight: fixed(height: 452) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      titolo
      sottotitolo
      linkCta {
        testoLink
        titleLink
        url
      }
    }
    abilitaHeroSafeArea
    articoliBox {
      ...ArticoloBox
    }
  }
}`;